import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Banner from '../components/banner';
import Footer from '../components/footer';
import EventList from '../components/event-list';
import Navigation from '../components/navigation';
import { SECTION_COMPONENTS } from './index';

const EventsPage = (props) => (
  <div>
    <Helmet title="The Divided Sky Bar and Restaurant" />
    <Navigation
      logoData={props.data.logo.gatsbyImageData}
    />
    <main className="lg:relative container my-8 mx-auto px-4">
      <h1 className="py-20 sm:py-24 pb-16 sm:pb-16 text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
        Events
      </h1>
      <EventList
        events={props.data.allEvent.edges.map((edge) => edge.node).filter((event) => new Date(event.date).getTime() > new Date().getTime())}
      />
      {props.data.eventsPage.extraSections.map((section) => {
        const {
          id,
          sectionType,
          ...componentProps
        } = section;
        const Component = SECTION_COMPONENTS[sectionType];
        return (
          <Component
            key={id}
            {...componentProps}
          />
        );
      })}
    </main>
    <Footer />
    {props.data.contentfulBanner.message && (
      <Banner data={props.data.contentfulBanner} />
    )}
  </div>
);

export const query = graphql`
  {
    logo: contentfulAsset(title: { eq: "Logo" }) {
      id
      title
      gatsbyImageData(width: 240)
    }
    contentfulBanner {
      id
      message
    }
    allEvent(sort: { fields: [date], order: [DESC] }) {
      edges {
        node {
          id
          timestamp: date
          displayDate
          date
          title
          description
          image {
            id
            alt
            gatsbyImageData(width: 240)
          }
        }
      }
    }
    eventsPage {
      heading
      extraSections {
        id
        sectionType
        ...HeroContent
        ...CarouselContent
        ...LocationSectionContent
      }
    }
  }
`;

export default EventsPage;
