import { InformationCircleIcon } from '@heroicons/react/solid';
import {
  GatsbyImage,
  getImage,
  ImageDataLike,
} from 'gatsby-plugin-image';
import React, { FC } from 'react';

interface Props {
  events: {
    id: string;
    displayDate: string;
    title: string;
    description: string;
    image?: {
      alt: string;
      gatsbyImageData: ImageDataLike;
    };
  }[];
}

const EventList: FC<Props> = (props) => (
  <div>
    <div className="max-w-4xl mx-auto py-4 sm:px-6 sm:py-8">
      {props.events.length === 0 && (
        <div className="rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                No events listed right now.
              </p>
              <p className="mt-3 text-sm md:mt-0 md:ml-6">
                <a
                  href="https://www.facebook.com/meyerspride/events"
                  target="_blank"
                  className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                >
                  Check on Facebook <span aria-hidden="true">&rarr;</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="space-y-16 sm:space-y-24">
        <div className="mt-6 flow-root px-4 sm:mt-10 sm:px-0">
          <div className="-my-6 divide-y divide-gray-200 sm:-my-10">
            {props.events.map((item) => (
              <div key={item.id} className="flex py-6 sm:py-10">
                <div className="min-w-0 flex-1 lg:flex lg:flex-col">
                  <div className="lg:flex-1">
                    <div className="sm:flex">
                      <div>
                        <h4 className="font-medium text-gray-900">{item.title}</h4>
                        <p className="my-2 text-sm text-gray-500">
                          {item.displayDate}
                        </p>
                        <div
                          className="rich-text mt-2 text-sm text-gray-500"
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {item.image && (
                  <div className="ml-4 flex-shrink-0 sm:m-0 sm:mr-6 sm:order-first">
                    <GatsbyImage
                      alt={item.image.alt}
                      className="col-start-2 col-end-3 sm:col-start-1 sm:row-start-1 sm:row-span-2 w-20 h-20 rounded-lg object-center object-cover md:w-40 md:h-40"
                      image={getImage(item.image.gatsbyImageData)!}
                      objectFit="cover"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EventList;
